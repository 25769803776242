import React, { useState } from 'react';
import styled from 'styled-components';
import VisibilitySensor from 'react-visibility-sensor';

import Fader, { FaderDirection } from 'app/ui/widgets/fader';

export { FaderDirection } from 'app/ui/widgets/fader';

const Container = styled.div`
  height: 100%;
  width: 100%;
`;

export type FaderScrolledProps = {
  direction: FaderDirection,
  children?: JSX.Element | JSX.Element[] | string,
  delay?: number,
}

export const FaderScrolled = (props: FaderScrolledProps) => {
  const [hasScrolled, setHasScrolled] = useState(false);

  return (
    <Container>
      <VisibilitySensor
        offset={{
          top: -128
        }}
        onChange={(isVisible) => {
          if (!hasScrolled && isVisible) {
            setHasScrolled(true);
          }
        }}
      >
        <Fader direction={props.direction} delay={props.delay} active={hasScrolled}>
          {props.children}
        </Fader>
      </VisibilitySensor>
    </Container>
  );
}

export default FaderScrolled;
