import React from 'react';
import styled from 'styled-components';

import * as theme from 'app/ui/theme';
import ContentLayout from 'app/ui/widgets/content-layout';
import BottomBanner from 'app/ui/widgets/bottom-banner';

import PropsIntroFlipGrid from './prop-intro-flip-grid';
import SectionIntro from './section-intro';
import SectionAbout from './section-about';
import SectionProjects from './section-projects';

const SectionContainer = styled.div`
  min-height: calc(100vh - ${theme.sizing.s128} * 2);
  padding: ${theme.sizing.s128} 0;
  position: relative;
`;

const StackContainer = styled.div`
  position: relative;
  width: 100%;
`;

const StackLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

const BGContainer = styled.div`
  overflow-x: clip;
  overflow-y: visible; 
`;

const RightAlignedContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: relative;
  overflow-y: visible; 
`;

const Home = () => {
  const bg = (
    <BGContainer>
      <RightAlignedContainer>
        <PropsIntroFlipGrid />
      </RightAlignedContainer>
    </BGContainer>
  );

  const fg = (
    <div>
      <ContentLayout>
        <SectionContainer>
          <SectionIntro />
        </SectionContainer>
        <SectionContainer>
          <SectionAbout />
        </SectionContainer>
        <SectionContainer>
          <SectionProjects />
        </SectionContainer>
      </ContentLayout>
      <BottomBanner />
    </div>
  );

  return (
    <StackContainer>
      <StackLayer>{bg}</StackLayer>
      <StackLayer>{fg}</StackLayer>
    </StackContainer>
  );
}

export default Home;
