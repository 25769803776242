import React from 'react';
import styled from 'styled-components';

import * as theme from 'app/ui/theme';
import { ColorUtils } from 'app/utils';
import ScalerScrolled, { ScalerAxis, ScalerOrigin } from 'app/ui/widgets/scaler-scrolled';
import Stack from 'app/ui/widgets/stack';
import StackElement from 'app/ui/widgets/stack-element';

const Container = styled.div`
  height: ${theme.sizing.s32};
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const LabelContainer = styled.div`
  width: ${theme.sizing.s128};
`;

const XpBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 80%;
  width: 100%;
  border-radius: ${theme.sizing.s4};
  background-color: ${ColorUtils.hexToRGBO(theme.color.orangeYellow, 0.2)};
  overflow: hidden;
`;

const XpBarFill = styled.div<{ width: number }>`
  height: 100%;
  width: ${(props) => props.width}%;
  border-radius: ${theme.sizing.s4};
  background-color: ${theme.color.orangeYellow};
`;

const XpBarPercentContainer = styled.div`
  height: calc(100% - ${theme.sizing.s8});
  width: calc(100% - ${theme.sizing.s16});
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: ${theme.sizing.s4} ${theme.sizing.s8};
`;

const XpBar = (props: {
  label?: string,
  delay?: number,
  percent?: number,
}) => {
  return (
    <Container>
      <LabelContainer>
        <theme.Body2>{props.label}</theme.Body2>
      </LabelContainer>
      <XpBarContainer>
        <Stack>
          <StackElement>
            <ScalerScrolled duration={1000} axis={ScalerAxis.x} origin={ScalerOrigin.left} delay={props.delay}>
              <XpBarFill width={props.percent ?? 50} />
            </ScalerScrolled>
          </StackElement>

          <StackElement>
            <XpBarPercentContainer>
              <theme.Overline>{props.percent}%</theme.Overline>
            </XpBarPercentContainer>
          </StackElement>
        </Stack>
      </XpBarContainer>
    </Container>
  );
}

export default XpBar;
