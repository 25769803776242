
export const sizing = {
  /** 4px */
  s4: '0.25rem',

  /** 8px */
  s8: '0.5rem',

  /** 16px */
  s16: '1rem',

  /** 24px */
  s24: '1.5rem',

  /** 32px */
  s32: '2rem',

  /** 48px */
  s48: '3rem',

  /** 64px */
  s64: '4rem',

  /** 96px */
  s96: '6rem',

  /** 128px */
  s128: '8rem',

  /** 128px */
  s256: '16rem',
}
