export class ColorUtils {
  private constructor() { }

  public static hexLerp(ahex: string, bhex: string, amount: number): string {
    let ah = +ahex.replace('#', '0x')
    let ar = ah >> 16, ag = (ah >> 8) & 0xff, ab = ah & 0xff;
    let bh = +bhex.replace('#', '0x');
    let br = bh >> 16, bg = (bh >> 8) & 0xff, bb = bh & 0xff;
    let rr = ar + amount * (br - ar);
    let rg = ag + amount * (bg - ag);
    let rb = ab + amount * (bb - ab);

    return '#' + ((1 << 24) + (rr << 16) + (rg << 8) + rb | 0).toString(16).slice(1);
  }

  public static hexToRGBO(hex: string, opacity: number = 1.0): string {
    let effHex = hex.replace('#', '');

    if (effHex.length === 3) {
      effHex = `${effHex[0]}${effHex[0]}${effHex[1]}${effHex[1]}${effHex[2]}${effHex[2]}`;
    }

    const r = parseInt(effHex.substring(0, 2), 16);
    const g = parseInt(effHex.substring(2, 4), 16);
    const b = parseInt(effHex.substring(4, 6), 16);

    return `rgba(${r},${g},${b},${opacity})`;
  }
}
