import React from 'react';
import styled from 'styled-components';
import { FiGithub } from 'react-icons/fi';
import { Grid } from '@material-ui/core';

import * as theme from 'app/ui/theme';
import FaderScrolled, { FaderDirection } from 'app/ui/widgets/fader-scrolled';
import ScalerScrolled, { ScalerAxis, ScalerOrigin } from 'app/ui/widgets/scaler-scrolled';
import Icon from 'app/ui/widgets/icon';

import Project from './project';

const Container = styled.div`
`;

const DescriptionContainer = styled.div`
  max-width: 45vw;
`;

const VerticalSpacer = styled.div`
  height: ${theme.sizing.s32};
`;

const SectionProjects = () => {
  return (
    <Container>
      <theme.Headline3>Projects</theme.Headline3>
      <VerticalSpacer />

      <DescriptionContainer>
        <theme.Body1>
          Here are a few projects that I've worked on. I love to contribute
          to <theme.Body1 shade={theme.palette.accent}>open source</theme.Body1> software.
        </theme.Body1>
      </DescriptionContainer>

      <theme.Body1>
        <br />
        This section is coming soon :)
        <br />
        <br />
        <Icon link={'https://github.com/josiahsrc'}><FiGithub /></Icon>
      </theme.Body1>

      <VerticalSpacer />

      {/* <Grid container spacing={10}>
        <Project title={'Flutter Dough'} />
        <Project />
        <Project />
        <Project />
        <Project />
      </Grid> */}
    </Container>
  );
}

export default SectionProjects;
