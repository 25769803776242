import React from 'react';
import styled from 'styled-components';

import * as theme from 'app/ui/theme';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: ${theme.sizing.s128};
  width: 100%;
`;

const BottomBanner = () => {
  return (
    <Container>
      <theme.Body2 shade={theme.color.greyGainsboro}>
        Copyright © 2020 Josiah Saunders. All rights reserved.
      </theme.Body2>
    </Container>
  );
}

export default BottomBanner;
