import styled from 'styled-components';

type TypographyProps = {
  shade?: string;
  textAlign?: string;
};

export const Headline1 = styled.span<TypographyProps>`
  font-weight: 800;
  letter-spacing: 2pt;
  font-size: 96pt;
  color: ${props => props.shade ?? props.theme.text};
  text-align: ${props => props.textAlign};
`;

export const Headline2 = styled.span<TypographyProps>`
  font-weight: 800;
  letter-spacing: 2pt;
  font-size: 60pt;
  color: ${props => props.shade ?? props.theme.text};
  text-align: ${props => props.textAlign};
`;

export const Headline3 = styled.span<TypographyProps>`
  font-weight: 400;
  letter-spacing: 1pt;
  font-size: 48pt;
  color: ${props => props.shade ?? props.theme.text};
  text-align: ${props => props.textAlign};
`;

export const Headline4 = styled.span<TypographyProps>`
  font-weight: 400;
  letter-spacing: 0;
  font-size: 34pt;
  color: ${props => props.shade ?? props.theme.text};
  text-align: ${props => props.textAlign};
`;

export const Headline5 = styled.span<TypographyProps>`
  font-weight: 500;
  letter-spacing: 0;
  font-size: 24pt;
  color: ${props => props.shade ?? props.theme.text};
  text-align: ${props => props.textAlign};
`;

export const Headline6 = styled.span<TypographyProps>`
  font-weight: 600;
  letter-spacing: 0;
  font-size: 20pt;
  color: ${props => props.shade ?? props.theme.text};
  text-align: ${props => props.textAlign};
`;

export const Subtitle1 = styled.span<TypographyProps>`
  font-weight: normal;
  letter-spacing: 0.15pt;
  font-size: 16pt;
  color: ${props => props.shade ?? props.theme.text};
  text-align: ${props => props.textAlign};
`;

export const Subtitle2 = styled.span<TypographyProps>`
  font-weight: medium;
  letter-spacing: 0.1pt;
  font-size: 14pt;
  color: ${props => props.shade ?? props.theme.text};
  text-align: ${props => props.textAlign};
`;

export const Body1 = styled.span<TypographyProps>`
  font-weight: normal;
  letter-spacing: 0.5pt;
  font-size: 16pt;
  color: ${props => props.shade ?? props.theme.text};
  text-align: ${props => props.textAlign};
`;

export const Body2 = styled.span<TypographyProps>`
  font-weight: normal;
  letter-spacing: 0.25pt;
  font-size: 14pt;
  color: ${props => props.shade ?? props.theme.text};
  text-align: ${props => props.textAlign};
`;

export const Button = styled.span<TypographyProps>`
  font-weight: medium;
  letter-spacing: 1.25pt;
  font-size: 14pt;
  color: ${props => props.shade ?? props.theme.text};
  text-align: ${props => props.textAlign};
`;

export const Caption = styled.span<TypographyProps>`
  font-weight: normal;
  letter-spacing: 0.4pt;
  font-size: 12pt;
  color: ${props => props.shade ?? props.theme.text};
  text-align: ${props => props.textAlign};
`;

export const Overline = styled.span<TypographyProps>`
  font-weight: normal;
  letter-spacing: 1.5pt;
  font-size: 10pt;
  color: ${props => props.shade ?? props.theme.text};
  text-align: ${props => props.textAlign};
`;
