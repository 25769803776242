import React, { useState } from 'react';
import styled from 'styled-components';
import VisibilitySensor from 'react-visibility-sensor';

import Scaler, { ScalerAxis, ScalerOrigin } from 'app/ui/widgets/scaler';

export { ScalerAxis, ScalerOrigin } from 'app/ui/widgets/scaler';

const Container = styled.div`
  height: 100%;
  width: 100%;
`;

export type ScalerScrolledProps = {
  axis: ScalerAxis,
  origin: ScalerOrigin,
  children?: JSX.Element | JSX.Element[] | string,
  delay?: number,
  duration?: number,
}

export const ScalerScrolled = (props: ScalerScrolledProps) => {
  const [hasScrolled, setHasScrolled] = useState(false);

  return (
    <Container>
      <VisibilitySensor
        offset={{
          top: -128
        }}
        onChange={(isVisible) => {
          if (!hasScrolled && isVisible) {
            setHasScrolled(true);
          }
        }}
      >
        <Scaler 
          axis={props.axis} 
          origin={props.origin} 
          delay={props.delay} 
          active={hasScrolled}
          duration={props.duration}
        >
          {props.children}
        </Scaler>
      </VisibilitySensor>
    </Container>
  );
}

export default ScalerScrolled;
