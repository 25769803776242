import { color } from './color';

export * from './color';
export * from './global';
export * from './shadow';
export * from './sizing';
export * from './typography';

export const palette = {
  text: color.blackRich,
  textLight: color.greyLavender,
  textVeryLight: color.greyGainsboro,

  accent: color.purpleFlirt,

  error: color.redBlood,
};
