import React from 'react';
import styled from 'styled-components';

import * as theme from 'app/ui/theme';
import { AssetUtils } from 'app/utils';
import FaderScrolled, { FaderDirection } from 'app/ui/widgets/fader-scrolled';
import ScalerScrolled, { ScalerAxis, ScalerOrigin } from 'app/ui/widgets/scaler-scrolled';

import XpBar from './xp-bar';

const ContentContainer = styled.div`
`;

const SegmentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-gap: ${theme.sizing.s64};
  grid-template-columns: repeat(3, 1fr);
`;

const LeftAboutSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* text-align: center; */
  grid-column: 1 / 2;
  grid-row: 1;
`;

const RightAboutSection = styled.div`
  grid-column: 2 / 4;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const VerticalSpacer = styled.div`
  height: ${theme.sizing.s32};
`;

const ProfilePicContainer = styled.div`
  position: relative;
  width: 10vw;

  :before {
    content:     "";
    display:     block;
    padding-top: 100%;
  }

  /* display: inline-block;
  width: 10vw;
  padding-bottom: 10vw;
  height: 0; */
`;

const ProfilePicInnerContainer = styled.div`
  position: absolute;
  top:      0;
  left:     0;
  bottom:   0;
  right:    0;
`;

const ProfilePic = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-image: url(${AssetUtils.getAssetURL(AssetUtils.PROFILE_PIC)});
  background-size: cover;
`;

const SectionAbout = () => {
  return (
    <ContentContainer>
      <theme.Headline3>About</theme.Headline3>

      <VerticalSpacer />

      <SegmentContainer>
        <LeftAboutSection>
          {/* Profile pic */}
          <ProfilePicContainer>
            <ProfilePicInnerContainer>
              <ScalerScrolled axis={ScalerAxis.all} origin={ScalerOrigin.center} delay={900}>
                <ProfilePic />
              </ScalerScrolled>
            </ProfilePicInnerContainer>
          </ProfilePicContainer>

          <VerticalSpacer />

          {/* Short description */}
          <FaderScrolled direction={FaderDirection.up} delay={1200}>
            <theme.Caption>
              A
              <theme.Caption shade={theme.palette.accent}> versatile </theme.Caption>
              full-stack developer from Utah. I have a passion for building
              <theme.Caption shade={theme.palette.accent}> awesome </theme.Caption>
              mobile and web applications.
            </theme.Caption>
          </FaderScrolled>
        </LeftAboutSection>

        <RightAboutSection>
          {/* <FaderScrolled direction={FaderDirection.down} delay={500}> */}
          <theme.Headline6>Stats</theme.Headline6>

          {/* Technologies */}
          <XpBar label={'Flutter'} percent={95} delay={600}></XpBar>
          <XpBar label={'Unity'} percent={91} delay={700}></XpBar>
          <XpBar label={'Angular'} percent={86} delay={800}></XpBar>
          <XpBar label={'React'} percent={60} delay={900}></XpBar>

          {/* Languages */}
          <VerticalSpacer />
          <XpBar label={'Dart'} percent={100} delay={1000}></XpBar>
          <XpBar label={'C#'} percent={94} delay={1100}></XpBar>
          <XpBar label={'HTML/CSS'} percent={76} delay={1200}></XpBar>

          {/* Skills */}
          <VerticalSpacer />
          <XpBar label={'UI Design'} percent={82} delay={1300}></XpBar>
          {/* </FaderScrolled> */}
        </RightAboutSection>
      </SegmentContainer>
    </ContentContainer>
  );
}

export default SectionAbout;
