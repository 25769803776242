export const color = {
  titaniumYellow: '#F4E409',

  orangeYellow: '#F6DA6D',
  orangOchre: '#C36F09',
  orangeRust: '#A63C06',

  redBlood: '#710000',

  white: '#FDFFFF',
  whiteGhost: '#E6E7EF',

  black: '#000000',
  blackRich: '#08090D',
  blackRasin: '#181A25',
  blackSpaceCadet: '#272B3F',

  greyLavender: '#999CAD',
  greyGainsboro: '#DDDEE4',

  purpleIris: '#6153CC',
  purpleFlirt: '#A60067',

  blueUltramarine: '#4361EE',
};
