import React from "react";
import styled from "styled-components";
import { useSpring, animated } from 'react-spring';
import * as easings from 'd3-ease'

const Container = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

const ScaledContainer = styled.div<{ origin: ScalerOrigin }>`
  height: 100%;
  width: 100%;
  position: absolute;
  transform-origin: ${(props) => getCSSOrigin(props.origin)};
`;

export enum ScalerAxis {
  x = 0,
  y = 1,
  all = 2,
}

export enum ScalerOrigin {
  center = 0,
  left = 1,
  right = 2,
  top = 3,
  bottom = 4,
}

export type ScalerProps = {
  axis: ScalerAxis,
  active: boolean,
  origin: ScalerOrigin,
  delay?: number,
  duration?: number,
  children?: JSX.Element | JSX.Element[] | string,
}

function getCSSScale(axis: ScalerAxis, size: number): string {
  if (axis === ScalerAxis.all) {
    return `scale(${size})`;
  } else if (axis === ScalerAxis.x) {
    return `scaleX(${size})`;
  } else if (axis === ScalerAxis.y) {
    return `scaleY(${size})`;
  } else {
    throw Error();
  }
}

function getCSSOrigin(origin: ScalerOrigin): string {
  if (origin === ScalerOrigin.center) {
    return `center`;
  } else if (origin === ScalerOrigin.left) {
    return `left`;
  } else if (origin === ScalerOrigin.right) {
    return `right`;
  } else if (origin === ScalerOrigin.top) {
    return `top`;
  } else if (origin === ScalerOrigin.bottom) {
    return `bottom`;
  } else {
    throw Error();
  }
}

const Scaler = (props: ScalerProps) => {
  const spring = useSpring({
    transform: props.active ? getCSSScale(props.axis, 1) : getCSSScale(props.axis, 0.0),
    from: {
      transform: getCSSScale(props.axis, 0.0),
    },
    delay: props.delay ?? 500,
    config: {
      duration: props.duration ?? 500,
      mass: 1,
      tension: 500,
      friction: 17,
      easing: easings.easeCubic,
    },
  });

  return (
    <Container>
      <ScaledContainer as={animated.div} origin={props.origin} style={spring}>
        {props.children}
      </ScaledContainer>
    </Container>
  );
}

export default Scaler;
