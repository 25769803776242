import React from 'react';
import styled from 'styled-components';

import * as theme from 'app/ui/theme';
import FaderScrolled, { FaderDirection } from 'app/ui/widgets/fader-scrolled';

const Container = styled.div`
  padding-top: 15vh;
`;

const SectionIntro = () => {
  return (
    <Container>
      <FaderScrolled direction={FaderDirection.left} delay={300}>
        <theme.Headline2>
          Hello.
       </theme.Headline2>
      </FaderScrolled>
      <FaderScrolled direction={FaderDirection.left} delay={1050}>
        <theme.Headline6 shade={theme.palette.textLight}>
          I'm <theme.Headline6 shade={theme.palette.accent}> Josiah Saunders</theme.Headline6>, a software engineer.
        </theme.Headline6>
      </FaderScrolled>
    </Container>
  );
}

export default SectionIntro;
