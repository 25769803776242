import React from 'react';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';

const OuterFlippyContainer = styled.div`
  background-color: transparent;
  height: 100%;
  width: 100%;
`;

const InnerFlippyContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
`;

const FrontSideContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
`;

const BackSideContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform: rotateY(180deg);
`;

export enum FlippyTileDirection {
  left = 0,
  up = 1,
  right = 2,
  down = 3,
}

export type FlippyTileProps = {
  flipped: boolean,
  direction: FlippyTileDirection,
  frontChild?: JSX.Element,
  backChild?: JSX.Element,
  flipDuration?: number,
  initiallyFlipped?: boolean,
  onFlipComplete?: (flipped: boolean) => void;
  perspective?: string | number,
  origin?: Offset,
}

interface Offset {
  x: number,
  y: number,
}

function inferOrigin(direction: FlippyTileDirection): Offset {
  if (direction === FlippyTileDirection.left) {
    return { x: 0.0, y: 0.5, };
  } else if (direction === FlippyTileDirection.up) {
    return { x: 0.5, y: 0.0, };
  } else if (direction === FlippyTileDirection.right) {
    return { x: 1.0, y: 0.5, };
  } else if (direction === FlippyTileDirection.down) {
    return { x: 0.5, y: 1.0, };
  }

  throw Error(`${direction} not implemented.`);
}

function getCSSRotation(flipped: boolean, direction: FlippyTileDirection): string {
  if (direction === FlippyTileDirection.left) {
    return `rotateY(${flipped ? -180 : 0}deg)`;
  } else if (direction === FlippyTileDirection.up) {
    return `rotateX(${flipped ? 180 : 0}deg)`;
  } else if (direction === FlippyTileDirection.right) {
    return `rotateY(${flipped ? 180 : 0}deg)`;
  } else if (direction === FlippyTileDirection.down) {
    return `rotateX(${flipped ? -180 : 0}deg)`;
  }

  throw Error(`${direction} not implemented.`);
}

export function FlippyTile(props: FlippyTileProps): JSX.Element {
  const flipDuration: number = props.flipDuration ?? 300;
  const origin = props.origin ?? inferOrigin(props.direction);
  const rotation = getCSSRotation(props.flipped, props.direction);
  const initialRotation = getCSSRotation(props.initiallyFlipped ?? props.flipped, props.direction)
  const onFlipComplete = props.onFlipComplete ?? ((_: any) => { });

  let perpective = `200px`;
  if (typeof props.perspective === "number")
    perpective = `${perpective}px`;
  else if (typeof props.perspective === "string")
    perpective = props.perspective;

  const spring = useSpring({
    transform: `perspective(${perpective}px) ${rotation}`,
    transformOrigin: `${origin.x * 100}% ${origin.y * 100}%`,
    from: {
      transform: `perspective(${perpective}) ${initialRotation}`,
    },
    config: {
      duration: flipDuration,
    },
    onRest: () => onFlipComplete(props.flipped),
  });

  return <OuterFlippyContainer>
    <InnerFlippyContainer as={animated.div} style={spring}>
      <FrontSideContainer>
        {props.frontChild}
      </FrontSideContainer>
      <BackSideContainer>
        {props.backChild}
      </BackSideContainer>
    </InnerFlippyContainer>
  </OuterFlippyContainer>
}

export default FlippyTile;
