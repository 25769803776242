import useViewportWidth from "app/ui/hooks/use-viewport-width";

export enum Viewport {
  mobile,
  desktop,
}

const useViewport = () => {
  const [width] = useViewportWidth();

  if (width < 620) {
    return [Viewport.mobile];
  }

  return [Viewport.desktop];
}

export default useViewport;
