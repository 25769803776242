import React, { ReactNode } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Home from 'app/ui/views/home';
import { GlobalStyles } from 'app/ui/theme';

class App extends React.Component {
  public render(): ReactNode {
    return (
      <div>
        <GlobalStyles />
        <Router>
          <Route path="/" component={Home} />
        </Router>
      </div>
    );
  }
}

export default App;
