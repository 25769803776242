import React from "react";
import styled from "styled-components";
import { useSpring, animated } from 'react-spring';
import * as easings from 'd3-ease'

const Container = styled.div`
  height: 100%;
  width: 100%;
`;

export enum FaderDirection {
  up = 0,
  down = 1,
  left = 2,
  right = 3,
}

export type FaderProps = {
  direction: FaderDirection,
  active: boolean,
  delay?: number,
  magnitude?: number | string,
  duration?: number,
  children?: JSX.Element | JSX.Element[] | string,
}

function getCSSTranslation(dir: FaderDirection, mag: number | string): string {
  let effectiveMag: any = mag;
  if ((typeof mag) === `number`) {
    effectiveMag = `${mag}px`;
  }

  if (dir === FaderDirection.up) {
    return `translate(0px, ${effectiveMag})`
  } else if (dir === FaderDirection.down) {
    return `translate(0px, -${effectiveMag})`
  } else if (dir === FaderDirection.left) {
    return `translate(-${effectiveMag}, 0px)`
  } else if (dir === FaderDirection.right) {
    return `translate(${effectiveMag}, 0px)`
  } else {
    throw Error();
  }
}

const Fader = (props: FaderProps) => {
  const mag = props.magnitude ?? 32;
  const duration = props.duration ?? 500;

  const spring = useSpring({
    opacity: props.active ? 1.0 : 0.0,
    transform: props.active ? getCSSTranslation(props.direction, 0) : getCSSTranslation(props.direction, mag),
    from: {
      opacity: 0.0,
      transform: getCSSTranslation(props.direction, mag),
    },
    delay: props.delay ?? 500,
    config: {
      duration: duration,
      mass: 1,
      tension: 500,
      friction: 17,
      easing: easings.easeBack,
    },
  });

  return (
    <Container>
      <animated.div style={spring}>
        {props.children}
      </animated.div>
    </Container>
  );
}

export default Fader;
