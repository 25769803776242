import { color } from './color';
import { ColorUtils } from 'app/utils';

export const shadow = {
  lightOffsetX: `0px`,
  lightOffsetY: `2px`,
  lightBlur: `8px`,
  lightSpread: `0px`,
  lightBox: `0px 2px 8px 0px ${ColorUtils.hexToRGBO(color.black, 0.2)}`,
}
