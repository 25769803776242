import React from 'react';
import styled from 'styled-components';

import * as theme from 'app/ui/theme';
import { ColorUtils } from 'app/utils'
import FlippyTileGrid, { FTG } from 'app/ui/widgets/flippy-tile-grid';

const Container = styled.div`
  height: 63vw;
  width: 63vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  overflow-y: hidden;
  overflow-x: hidden;
  transform: translateY(-5vw);
`;

const TrfmContainer = styled.div`
  height: 70%;
  width: 70%;
  transform: perspective(60vw) rotateX(20deg) rotateY(-30deg) rotateZ(30deg);
  transform-style: preserve-3d;
`;

const GridCell = styled.div`
  height: calc(100% - 3px);
  width: calc(100% - 3px);
  background-color: ${theme.color.orangeYellow};
  border-style: solid;
  border-width: 3px;
  border-color: ${theme.color.orangeYellow};
`;

const GridCellShadow = styled.div`
  height: 100%;
  width: 100%;
  backface-visibility: hidden;
  z-index: -10;
`;

const GRID: FTG[][] = [
  [FTG.X, FTG.X, FTG.X, FTG.S],
  [FTG.X, FTG.X, FTG.X, FTG._],
  [FTG._, FTG.X, FTG.X, FTG._],
  [FTG._, FTG.X, FTG.X, FTG.X],
];

const PropIntroFlipGrid = () => {
  console.log(ColorUtils.hexToRGBO(theme.color.white, 0.1));

  return (
    <Container>
      <TrfmContainer>
        <FlippyTileGrid
          doFlip={true}
          grid={GRID}
          perspective={`50vw`}
          tileFrontBuilderCallback={(_, __) =>
            <GridCell>
              <GridCellShadow />
            </GridCell>
          }
          tileBackBuilderCallback={(_, __) => {
            return (
              <GridCell>
                <GridCellShadow />
              </GridCell>
            );
          }}
        />
      </TrfmContainer>
    </Container>
  );
}

export default PropIntroFlipGrid;
