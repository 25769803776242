import React from 'react';
import styled from 'styled-components';

import * as theme from 'app/ui/theme';
import { IconContext } from 'react-icons/lib';

const Container = styled.div`
  height: ${theme.sizing.s64};
  width: ${theme.sizing.s64};
  display: table-cell;
`;

const Icon = (props: {
  children?: JSX.Element | JSX.Element[] | string,
  link?: string,
  onClicked?: () => void,
}) => {
  let icon = (
    <IconContext.Provider value={{ size: theme.sizing.s32, color: theme.color.blackRich }}>
      {props.children}
    </IconContext.Provider>
  );

  if (props.onClicked !== undefined) {
    return (
      <Container
        style={{ cursor: 'pointer' }}
        onClick={props.onClicked}
      >
        {icon}
      </Container>
    );
  }
  else if (props.link !== undefined) {
    return (
      <Container style={{ cursor: 'pointer' }}>
        <a href={props.link}>
          {icon}
        </a>
      </Container>
    );
  }

  return (
    <Container>
      {icon}
    </Container>
  );
}

export default Icon;
