import React, { ReactNode } from 'react';
import styled from 'styled-components';

import FlippyTile, { FlippyTileDirection } from 'app/ui/widgets/flippy-tile';

const GridContainer = styled.div`
  display: grid;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-auto-rows: 1fr;
  grid-auto-columns: 1fr;
  height: 100%;
  width: 100%;
`;

export enum FTG {
  S = 0,
  X = 1,
  _ = 2,
}

export type FlippyTileGridProps = {
  grid: FTG[][],
  tileFrontBuilderCallback: (rowIndex: number, colIndex: number) => JSX.Element;
  tileBackBuilderCallback: (rowIndex: number, colIndex: number) => JSX.Element;
  minFlipDuration?: number,
  maxFlipDuration?: number,
  doFlip?: boolean,
  perspective?: string | number,
}

type Idx2D = {
  absi: number,
  rowidx: number,
  colidx: number,
}

type Activation = {
  zindex: number,
  duration: number,
}

type FlippyGridState = {
  dirs: (FlippyTileDirection | undefined)[][],
  activities: (Activation | undefined)[][],
  tileIndex: number,
}

export class FlippyTileGrid extends React.Component<FlippyTileGridProps, FlippyGridState> {
  constructor(props: FlippyTileGridProps) {
    super(props)

    this.state = {
      dirs: this.createDirGrid(),
      activities: this.createActivationGrid(),
      tileIndex: 0,
    };
  }

  public render(): ReactNode {
    if (!this.props.doFlip) {
      return (<div></div>);
    }

    const tiles = this.buildTileElements();

    return (
      <GridContainer>
        {tiles}
      </GridContainer>
    );
  }

  private buildTileElements(): JSX.Element[] {
    const tiles: JSX.Element[] = [];

    this.enumerateGrid((curr) => {
      const rowidx = curr.rowidx;
      const colidx = curr.colidx;
      const i = curr.absi;
      const ftg = this.getFtgGridElement(curr);
      const dir = this.getDirGridElement(curr);
      const act = this.getDefaultActivationGridElement(curr);
      const tileStyle = { gridRow: rowidx + 1, gridColumn: colidx + 1, zIndex: -act.zindex };
      const duration = this.getTileDuration(this.state.tileIndex);

      if (ftg === FTG._ || dir === undefined) {
        tiles.push(<div key={i} style={tileStyle}></div>);
        return;
      }

      tiles.push(
        <div key={i} style={tileStyle}>
          <FlippyTile
            perspective={this.props.perspective}
            flipped={false}
            initiallyFlipped={true}
            direction={dir}
            flipDuration={duration}
            frontChild={this.props.tileFrontBuilderCallback(rowidx, colidx)}
            backChild={this.props.tileBackBuilderCallback(rowidx, colidx)}
            onFlipComplete={(flipped) => {
              if (flipped === false) {
                this.onTileFlipped(curr);
              }
            }}
          />
        </div>
      );
    });

    return tiles;
  }

  private async onTileFlipped(index: Idx2D): Promise<void> {
    if (this.getRawActivationGridElement(index) === undefined) {

      this.setActivationGridElement(index, {
        zindex: this.state.tileIndex,
        duration: this.getTileDuration(this.state.tileIndex),
      })

      const count = await this.traverseNextNeighbors(index);

      this.setState({
        dirs: this.getDirGrid(),
        activities: this.getActivationGrid(),
        tileIndex: this.state.tileIndex + count,
      });
    }
  }

  private getTileDuration(index: number): number {
    const t = index / this.getTotalElementCount();
    const duration = this.lerp(this.getMaxFlipDuration(), this.getMinFlipDuration(), t);
    return duration;
  }

  private getRowCount(): number {
    return this.getFtgGrid().length;
  }

  private getColCount(): number {
    return this.getFtgGrid()[0].length;
  }

  private getFtgGridElement(index: Idx2D): FTG {
    return this.getFtgGrid()[index.rowidx][index.colidx];
  }

  private getDirGridElement(index: Idx2D): FlippyTileDirection | undefined {
    return this.getDirGrid()[index.rowidx][index.colidx];
  }

  private getDefaultActivationGridElement(index: Idx2D): Activation {
    return this.getActivationGrid()[index.rowidx][index.colidx] ?? {
      zindex: -1,
      duration: this.getMaxFlipDuration(),
    };
  }

  private getRawActivationGridElement(index: Idx2D): Activation | undefined {
    return this.getActivationGrid()[index.rowidx][index.colidx];
  }

  private getMinFlipDuration(): number {
    return this.props.minFlipDuration ?? 50;
  }

  private getMaxFlipDuration(): number {
    return this.props.maxFlipDuration ?? 300;
  }

  private setDirGridElement(index: Idx2D, value: FlippyTileDirection | undefined): void {
    this.getDirGrid()[index.rowidx][index.colidx] = value;
  }

  private setActivationGridElement(index: Idx2D, value: Activation | undefined): void {
    this.getActivationGrid()[index.rowidx][index.colidx] = value;
  }

  private getFtgGrid(): FTG[][] {
    return this.props.grid;
  }

  private getDirGrid(): (FlippyTileDirection | undefined)[][] {
    return this.state.dirs;
  }

  private getActivationGrid(): (Activation | undefined)[][] {
    return this.state.activities;
  }

  private getTotalElementCount(): number {
    return this.getRowCount() * this.getColCount();
  }

  private enumerateGrid(callback: (current: Idx2D) => void): void {
    const rowCount = this.getRowCount();
    const colCount = this.getColCount();
    const tileCount = rowCount * colCount;

    for (let i = 0; i < tileCount; ++i) {
      const rowidx = Math.floor(i / rowCount);
      const colidx = Math.floor(i % colCount);
      callback({ rowidx: rowidx, colidx: colidx, absi: i });
    }
  }

  private async traverseNextNeighbors(index: Idx2D): Promise<number> {
    let neighborIndices: Idx2D[] = [];

    const nL: Idx2D = { rowidx: index.rowidx - 1, colidx: index.colidx, absi: -1 };
    const nR: Idx2D = { rowidx: index.rowidx + 1, colidx: index.colidx, absi: -1 };
    const nT: Idx2D = { rowidx: index.rowidx, colidx: index.colidx + 1, absi: -1 };
    const nB: Idx2D = { rowidx: index.rowidx, colidx: index.colidx - 1, absi: -1 };

    if (this.isIndexInBounds(nL) && this.getFtgGridElement(nL) !== FTG._ && this.getDirGridElement(nL) === undefined)
      neighborIndices.push(nL);
    if (this.isIndexInBounds(nR) && this.getFtgGridElement(nR) !== FTG._ && this.getDirGridElement(nR) === undefined)
      neighborIndices.push(nR);
    if (this.isIndexInBounds(nT) && this.getFtgGridElement(nT) !== FTG._ && this.getDirGridElement(nT) === undefined)
      neighborIndices.push(nT);
    if (this.isIndexInBounds(nB) && this.getFtgGridElement(nB) !== FTG._ && this.getDirGridElement(nB) === undefined)
      neighborIndices.push(nB);

    for (let i = 0; i < neighborIndices.length; ++i) {
      let lastIndex = index;
      let nextIndex = neighborIndices[i];

      if (nextIndex.colidx < lastIndex.colidx) {
        this.setDirGridElement(nextIndex, FlippyTileDirection.right);
      } else if (nextIndex.colidx > lastIndex.colidx) {
        this.setDirGridElement(nextIndex, FlippyTileDirection.left);
      } else if (nextIndex.rowidx < lastIndex.rowidx) {
        this.setDirGridElement(nextIndex, FlippyTileDirection.down);
      } else if (nextIndex.rowidx > lastIndex.rowidx) {
        this.setDirGridElement(nextIndex, FlippyTileDirection.up);
      } else {
        throw Error('This should not be called');
      }
    }

    return neighborIndices.length;
  }

  private lerp(a: number, b: number, t: number): number {
    return a + t * (b - a);
  }

  private searchFtgGrid(ftg: FTG): Idx2D[] {
    let result: Idx2D[] = [];

    this.enumerateGrid((index) => {
      if (this.getFtgGridElement(index) === ftg) {
        result.push(index);
      }
    });

    return result;
  }

  private isIndexInBounds(index: Idx2D): boolean {
    return index.rowidx >= 0 &&
      index.rowidx < this.getRowCount() &&
      index.colidx >= 0 &&
      index.colidx < this.getColCount();
  }

  private createDirGrid(): (FlippyTileDirection | undefined)[][] {
    const result: (FlippyTileDirection | undefined)[][] = [];
    const rowCount = this.getRowCount();
    const colCount = this.getColCount();

    for (let i = 0; i < rowCount; i++) {
      result[i] = [];
      for (let j: number = 0; j < colCount; j++) {
        result[i][j] = undefined;
      }
    }

    const startingIndices = this.searchFtgGrid(FTG.S);
    for (let i = 0; i < startingIndices.length; ++i) {
      const index = startingIndices[i];
      result[index.rowidx][index.colidx] = FlippyTileDirection.up;
    }

    return result;
  }

  private createActivationGrid(): (Activation | undefined)[][] {
    const result: (Activation | undefined)[][] = [];
    const rowCount = this.getRowCount();
    const colCount = this.getColCount();

    for (let i = 0; i < rowCount; i++) {
      result[i] = [];
      for (let j: number = 0; j < colCount; j++) {
        result[i][j] = undefined;
      }
    }

    return result;
  }
}

export default FlippyTileGrid;
