import React from 'react';
import styled from 'styled-components';

import * as theme from 'app/ui/theme';
import useViewport, { Viewport } from 'app/ui/hooks/use-viewport';

const DesktopContentContainer = styled.div`
  padding: 0 ${theme.sizing.s128};
`;

const MobileContentContainer = styled.div`
  padding: 0 ${theme.sizing.s4};
`;

export type ContentLayoutProps = {
  children: string | JSX.Element | JSX.Element[],
}

const ContentLayout = (props: ContentLayoutProps) => {
  const [viewport] = useViewport();

  if (viewport === Viewport.desktop) {
    return (
      <DesktopContentContainer>
        {props.children}
      </DesktopContentContainer>
    );
  } else if (viewport === Viewport.mobile) {
    return (
      <MobileContentContainer>
        {props.children}
      </MobileContentContainer>
    );
  }

  throw Error();
}

export default ContentLayout;
